import {connect} from "react-redux";
import React, {Component} from "react";
import {Grid, Image} from "semantic-ui-react";
import icon from "../images/jgglogo_202102.svg"
import {formatQuery} from "../controllers/utilities/string-utils";
import PaymentResult from "../components/credit-purchase/payment-result";
import PaymentResult1 from "../components/credit-purchase/payment-result1";
import iconGg from "../images/gg-logo.png";
import {environmentMode} from "../components/lib/ad-manage-api-lib";
import "../styles/bg.css";
import {navigate} from "../../.cache/gatsby-browser-entry";
import Helmet from "react-helmet";
import cookie from "react-cookies";

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings
    };
}, dispatch => {
    return {
        setTransactionId: (transactionId) => {
            dispatch({
                type: "SET_TRANSACTION_ID",
                value: transactionId
            });
        }
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        const {pendingTransactionId} = formatQuery(props.location.search);
        if(pendingTransactionId) {
            this.props.setTransactionId(pendingTransactionId);
        }
        this.state = {
            reload: ''
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.getDirection);
        // let inFifteenMinutes = new Date(new Date().getTime() + 24 * 3600 * 1000);
        // cookie.save("_alipaytransactionId", "", {
        //     path:"/",
        //     expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
        // });

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getDirection);
    }

    getDirection = () => {
        // this.setState({ state: this.state });
        const mode = detectOrient();
        if (mode === "LANDSCAPE") {
            if(typeof window !== `undefined`) {
                navigate(`/credit-purchase-result2/${this.props.location.search}`);
            }
        }
    }
    render() {
        const mode = detectOrient();
        let iconJgg = icon;
        if (environmentMode === "SANDBOX") iconJgg = iconGg;
        console.log(mode);
        if (mode === "LANDSCAPE") {
            if(typeof window !== `undefined`) {
                navigate(`/credit-purchase-result2/${this.props.location.search}`);
            }
        }
            return (
                <div style={{
                    minHeight: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}>
                    <Helmet>
                        <style>{'body:before { background-image:none; background-color: #FFF; }'}</style>
                    </Helmet>
                                    <PaymentResult q={this.props.location.search}/>
                </div>
            );
    }
});

function detectOrient(){
    if(typeof window !== `undefined`) {
        // let isIOS = "0";
        // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        //     // 暂时去掉ios两板功能
        //     isIOS = "1";
        // }
        // if (isIOS === "0") return "PORTRAIT";
        if (window.innerHeight >= window.innerWidth) {
            // 竖屏
            // console.log("竖屏");
            return "PORTRAIT";
        } else {
            // 横屏
            // console.log("横屏");
            return "LANDSCAPE";
        }
    }else{
        return "PORTRAIT";
    }
}